import {createEffect, createEvent, createStore, sample} from 'effector';
import {setError, setSuccess} from 'src/features/notifications';
import {settingApi} from 'src/shared/api';
import {Setting} from "pages/settings/splitting/types";

export interface Tariff {
  code: string;
  displayName: string;
  provID: number;
  providerName: string;
  tariffId: string;
}

export const $tariffs = createStore<Tariff[]>([]);
export const $setting = createStore<null|string>(null);
export const initDeliverySetting = createEvent();
export const changeSettingValue = createEvent<null|string>();
export const getSettingsFx = createEffect(settingApi.getSettings);
export const updateSettingFx = createEffect(settingApi.updateSetting);
export const getTariffsFx = createEffect(settingApi.getTariffs);

sample({
  clock: initDeliverySetting,
  target: getSettingsFx,
});

sample({
  clock: getSettingsFx.doneData,
  fn: ({ settings }: { settings: Setting[] }) => {
    const deliverySetting = settings.find(item => item.key === settingApi.SettingKeys.disabled_tariffs);
    return deliverySetting?.value ?? null;
  },
  target: $setting,
});

sample({
  clock: initDeliverySetting,
  target: getTariffsFx,
});

sample({
  clock: getTariffsFx.doneData,
  fn: ({tariffs}: { tariffs: Tariff[] }) => tariffs,
  target: $tariffs,
});

sample({
  clock: changeSettingValue,
  fn: (value: null|string) => ({key: settingApi.SettingKeys.disabled_tariffs, value}),
  target: updateSettingFx,
});

sample({
  clock: updateSettingFx.doneData,
  fn: () => 'Параметр успешно обновлен',
  target: [setSuccess, getSettingsFx],
});

sample({
  clock: updateSettingFx.failData,
  fn: () => 'Ошибка обновления',
  target: setError,
});
