import api from '../api';

export enum SettingKeys {
  intermediary_comission = 'intermediary_comission',
  service_comission = 'service_comission',
  frequency_of_checking_new_posts = 'frequency_of_checking_new_posts',
  use_split_payments = 'use_split_payments',
  disabled_tariffs = 'disabled_tariffs',
}

export interface UpdateSettingData {
  key: SettingKeys;
  value: string|null;
}

export const getSettings = () => api.post('/settings').then(response => response.data);

export const getTariffs = () => api.get('/../delivery/tariffs').then(response => response.data);

export const updateSetting = (setting: UpdateSettingData) =>
  api.put('/setting', setting).then(response => response.data);
